import { template as template_741d247a294741a684c244391adb63c9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_741d247a294741a684c244391adb63c9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
