import { template as template_33b183439bb349d788439b602c2e2207 } from "@ember/template-compiler";
const FKLabel = template_33b183439bb349d788439b602c2e2207(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
