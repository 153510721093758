import { template as template_94ae3a8fca1547778d388f5af6fec53f } from "@ember/template-compiler";
const FKText = template_94ae3a8fca1547778d388f5af6fec53f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
